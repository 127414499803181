import { gsap } from 'gsap';

export function Hero() {
  const hero = document.querySelector('[data-anim-hero]');
  const heroMoons = hero.querySelectorAll('[data-moon]');

  gsap.to(heroMoons[0], {
    rotation: '-=360',
    duration: 60,
    repeat: -1,
    ease: 'none',
  });
  gsap.to(heroMoons[1], {
    rotation: '+=360',
    duration: 80,
    repeat: -1,
    ease: 'none',
  });

}
