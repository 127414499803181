import { gsap } from 'gsap';
import { DrawSVGPlugin } from 'gsap/DrawSVGPlugin';

gsap.registerPlugin(DrawSVGPlugin);

export function Form() {
  // add active class
  const handleFocus = (e) => {
    const targetParent = e.target.parentNode;
    const border = targetParent.querySelector('svg path');
    targetParent.classList.add('is--active');

    gsap.to(border, { duration: 1, drawSVG: '0 100%' });
  };

  // remove active class
  const handleBlur = (e) => {
    const targetParent = e.target.parentNode;
    const border = targetParent.querySelector('svg path');
    gsap.to(border, { duration: 1, drawSVG: '45% 45%' });
    if (!e.target.value) {
      targetParent.classList.remove('is--active');
    }
  };

  // register events
  const bindEvents = (element) => {
    const inputField = element.querySelector('input, textarea, select');
    inputField.addEventListener('focus', handleFocus);
    inputField.addEventListener('blur', handleBlur);
  };

  const inputContainers = document.querySelectorAll(
    '.m-form__input:not(.m-form__input--checkbox)'
  );

  inputContainers.forEach((element) => {
    bindEvents(element);

    const border = element.querySelector('svg path');
    gsap.set(border, { drawSVG: '45% 45%' });
  });

  // FORM VALIDATION
  const form = document.querySelector('[data-js="form"]') as HTMLFormElement;
  const inputs: NodeListOf<HTMLInputElement> = document.querySelectorAll(
    'input, textarea, select'
  );

  form.reset();

  const formValidation = () => {
    let formError = false;
    for (let input of inputs) {

      let inputContainer = input.parentElement;
      inputContainer.classList.remove('is--error');
      if (input.hasAttribute('required')) {
        if (input.validity.patternMismatch) {
          addError(inputContainer, 'data-error-pattern');
          formError = true;
        }
        if (input.validity.valueMissing) {
          addError(inputContainer);
          formError = true;
        }
      }
      if (!formError) formError = checkInput(input);
    }
    return formError;
  };

  inputValueCheck(inputs);

  const API_URL = form.getAttribute('data-api');
  if (form) {
    form.onsubmit = (e) => {
      e.preventDefault();
      if (!formValidation()) {
        const dataToSend = [];
        for (let input of inputs) {
          let val = input.value;
          if (input.type == 'checkbox') {
            val = input.value == 'on' ? 'true' : 'false';
          }
          dataToSend.push({ Name: input.name, Value: val });
        }
        const fetchParams = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(dataToSend),
        };

        fetch(API_URL, fetchParams)
          .then((res) => res.json())
          .then((resData) => {
            form.reset();
            showTyp(resData.Success);
          });
      }
    };
  }

  const showTyp = (success) => {
    const typ = form.querySelector('[data-typ]');
    const text = success
      ? typ.getAttribute('data-message-success')
      : typ.getAttribute('data-message-fail');
    typ.innerHTML = text;
    setTimeout(() => {
      typ.innerHTML = '';
    }, 2000);
  };
}

const inputValueCheck = (inputs) => {
  for (let input of inputs) {
    input.onkeyup = () => {
      checkInput(input)
    };
  }
};

const checkInput = (input) => {
  let formError = false;
  let inputContainer = input.parentElement;
  inputContainer.classList.remove('is--error');
  if (input.hasAttribute('required')) {
    if (input.validity.patternMismatch) {
      addError(inputContainer, 'data-error-pattern');
      formError = true;
    }
    if (input.validity.valueMissing) {
      addError(inputContainer);
      formError = true;
    }
  }

  return formError;
};

// const removeError = (inputContainer) => {
//   inputContainer.classList.remove('is--error');
//   inputContainer.querySelector('[data-js="message"]').innerHTML = '';
// };

const addError = (inputContainer, dataMessage = 'data-error-empty') => {
  inputContainer.classList.add('is--error');
  inputContainer.querySelector('[data-js="message"]').innerHTML =
    inputContainer.getAttribute(`${dataMessage}`);
};
