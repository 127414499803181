// core version + navigation, pagination modules:
import { gsap } from 'gsap';
import { Physics2DPlugin } from 'gsap/Physics2DPlugin';

gsap.registerPlugin(
  Physics2DPlugin
);

export function Cursor() {
  const cursor = document.querySelector('[data-cursor]');

  let cursorPos = [0, 0];
  let followCursor = true;
  document.addEventListener('mousemove', (e) => {
    if (!followCursor) return;
    cursorPos = [e.clientX, e.clientY];

    const pX = cursorPos[0];
    const pY = cursorPos[1];

    if (pX > document.body.offsetWidth * 0.5) {
      cursor.classList.remove('is--left');
    } else {
      cursor.classList.add('is--left');
    }

    cursor.style.top = pY+'px';
    cursor.style.left = pX+'px';
  }, {passive: true});




  // TODO: easter egg
  // https://greensock.com/docs/v3/Plugins/Physics2DPlugin
  // let tween;
  // document.addEventListener('keydown', (e)=>{
  //   if (e.key == "Enter"){
  //     // followCursor = false;
  //       tween = gsap.to(cursor, {
  //       duration: 2.5,
  //       physics2D: {
  //         velocity: "random(200, 650)",
  //         angle: "random(250, 290)",
  //         gravity: 500
  //       },
  //       // delay: "random(0, 2.5)"

  //     });
  //   }
  // })


}
