import { gsap } from 'gsap';
import { SplitText } from 'gsap/SplitText';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { MotionPathPlugin } from 'gsap/MotionPathPlugin';
import { DrawSVGPlugin } from 'gsap/DrawSVGPlugin';
import { MorphSVGPlugin } from 'gsap/MorphSVGPlugin';

gsap.registerPlugin(
  ScrollTrigger,
  MotionPathPlugin,
  DrawSVGPlugin,
  MorphSVGPlugin,
  SplitText
);

export function Products() {
  const products = document.querySelector('[data-anim-products]');

  const analyze = products.querySelector('[data-anim-analyze]');
  const analyzeBgSpace = products.querySelector('[data-anim-analyze-bgSpace]');
  const analyzeBgMoons = products.querySelector('[data-anim-analyze-bgMoons]');
  const analyzeBgCircle = products.querySelector(
    '[data-anim-analyze-bgCircle]'
  );
  const analyzeBgCircleFill = products.querySelector(
    '[data-anim-analyze-bgCircleFill]'
  );
  const analyzeBgImage = products.querySelector('[data-anim-analyze-bgImage]');
  const analyzeHeader = products.querySelector('[data-anim-analyze-header]');
  const analyzeHeaderSplit = new SplitText(analyzeHeader, {type:'chars'});
  const analyzeLeft = products.querySelectorAll('[data-anim-analyze-left] p');
  const analyzeRight = products.querySelectorAll('[data-anim-analyze-right] p, [data-anim-analyze-right] li');
  const analyzeBtn = products.querySelector('[data-anim-analyze-right] a');
  const analyzeLeftSplit = new SplitText(analyzeLeft, {type:'words'});
  const analyzeRightSplit = new SplitText(analyzeRight, {type:'words'});

  const concept = products.querySelector('[data-anim-concept]');
  const conceptBgGraph = products.querySelector('[data-anim-concept-bgGraph]');
  const conceptBgImage = products.querySelector('[data-anim-concept-bgImage]');
  const conceptHeader = products.querySelector('[data-anim-concept-header]');
  const conceptHeaderSplit = new SplitText(conceptHeader, {type:'chars'});
  const conceptLeft = products.querySelectorAll('[data-anim-concept-left] p');
  const conceptRight = products.querySelectorAll('[data-anim-concept-right] p, [data-anim-concept-right] li');
  const conceptBtn = products.querySelector('[data-anim-concept-right] a');
  const conceptLeftSplit = new SplitText(conceptLeft, {type:'words'});
  const conceptRightSplit = new SplitText(conceptRight, {type:'words'});

  const design = products.querySelector('[data-anim-design]');
  const designBgGraph = products.querySelector('[data-anim-design-bgGraph]');
  const designBgImage = products.querySelector('[data-anim-design-bgImage]');
  const designHeader = products.querySelector('[data-anim-design-header]');
  const designHeaderSplit = new SplitText(designHeader, {type:'chars'});
  const designLeft = products.querySelectorAll('[data-anim-design-left] p');
  const designRight = products.querySelectorAll('[data-anim-design-right] p, [data-anim-design-right] li');
  const designBtn = products.querySelector('[data-anim-design-right] a');
  const designLeftSplit = new SplitText(designLeft, {type:'words'});
  const designRightSplit = new SplitText(designRight, {type:'words'});

  const validate = products.querySelector('[data-anim-validate]');
  const validateBgImage = products.querySelector(
    '[data-anim-validate-bgImage]'
  );
  const validateHand = validateBgImage.querySelector(
    '[data-anim-validate-hand]'
  );
  const validateHeader = products.querySelector('[data-anim-validate-header]');
  const validateHeaderSplit = new SplitText(validateHeader, {type:'chars'});
  const validateLeft = products.querySelectorAll('[data-anim-validate-left] p');
  const validateRight = products.querySelectorAll('[data-anim-validate-right] p, [data-anim-validate-right] li');
  const validateBtn = products.querySelector('[data-anim-validate-right] a');
  const validateLeftSplit = new SplitText(validateLeft, {type:'words'});
  const validateRightSplit = new SplitText(validateRight, {type:'words'});
  const all = [
    products,

    analyzeBgSpace,
    analyzeBgMoons,
    analyzeBgCircle,
    analyzeBgCircleFill,
    analyzeBgImage,
    analyzeHeader,
    analyzeHeaderSplit.chars,
    analyzeLeft,
    analyzeRight,
    analyzeBtn,
    analyzeLeftSplit.words,
    analyzeRightSplit.words,

    conceptBgGraph,
    conceptBgImage,
    conceptHeader,
    conceptHeaderSplit.chars,
    conceptLeft,
    conceptRight,
    conceptBtn,
    conceptLeftSplit.words,
    conceptRightSplit.words,

    designBgGraph,
    designBgImage,
    designHeader,
    designHeaderSplit.chars,
    designLeft,
    designRight,
    designBtn,
    designLeftSplit.words,
    designRightSplit.words,

    validateBgImage,
    validateHand,
    validateHeader,
    validateHeaderSplit.chars,
    validateLeft,
    validateRight,
    validateBtn,
    validateLeftSplit.words,
    validateRightSplit.words
  ];

  const analyzeMoons = analyzeBgMoons.querySelectorAll('[data-moon]');

  ScrollTrigger.saveStyles(all);

  ScrollTrigger.matchMedia({
    // desktop
    '(min-width: 1024px)': function () {
      // setup animations and ScrollTriggers for screens over 800px wide (desktop) here...
      // ScrollTriggers will be reverted/killed when the media query doesn't match anymore.

      // moons animation
        // gsap.to(analyzeMoons[0], {
        //   rotation: '+=360',
        //   duration: 40,
        //   repeat: -1,
        //   ease: 'none',
        // });
        // gsap.to(analyzeMoons[1], {
        //   rotation: '+=360',
        //   duration: 50,
        //   repeat: -1,
        //   ease: 'none',
        // });
      gsap.set(analyzeMoons[0], {
        transformOrigin: 'top left'
      });
      gsap.fromTo(analyzeMoons[0], {
        rotation: '-15deg',},{
        rotation: '-35deg',
        duration: gsap.utils.random(8, 12),
        repeat: -1,
        yoyo: true,
        ease: 'Back.easeInOut'
      });
      gsap.set(analyzeMoons[1], {
        transformOrigin: 'top left'
      });
      gsap.fromTo(analyzeMoons[1], {
        rotation: '60deg',},{
        rotation: '80deg',
        duration: gsap.utils.random(8, 12),
        repeat: -1,
        yoyo: true,
        ease: 'Back.easeInOut'
      });
      gsap.set(analyzeMoons[2], {
        transformOrigin: 'center 0%'
      });
      gsap.fromTo(analyzeMoons[2], {
        rotation: '0',
      },{
        rotation: '360deg_ccw',
        duration: gsap.utils.random(20, 30),
        repeat: -1,
        ease: 'none'
      });

      // --------
      // scroll animation 

      let tlAll = gsap.timeline({
        // duration: .1,
        defaults:{
          ease: 'none'
        },
        ease: 'none',
        scrollTrigger: {
          trigger: products,
          start: 'top top',
          end: '+=20000',
          scrub: true,
          pin: true,
          // anticipatePin: 1,
},
      });

      tlAll.set(analyze, { zIndex: 2}, '<0');
      tlAll.fromTo(analyzeBgSpace, { opacity: 0 }, {opacity: 1, duration: 8});
      //analyze ------------------------------
      tlAll.from( analyzeHeaderSplit.chars, { autoAlpha: 0, filter: 'blur(10px)', duration: 1, stagger: {amount: 1, from: 'edges' }}, '<0');
      tlAll.from( analyzeMoons[0], {autoAlpha: 0, filter: 'blur(20px)', scale: 1.2, duration: 4 }, '<0');
      tlAll.from( analyzeMoons[1], {autoAlpha: 0, filter: 'blur(20px)', duration: 4 }, '<0');
      tlAll.from( analyzeBgImage, { autoAlpha: 0, duration: 2 }, '<3');
      tlAll.to(   analyzeBgSpace, { width: '45rem', duration: 2 }, '<25%');
      tlAll.fromTo(products, { backgroundColor: '#00000000'}, { backgroundColor: '#4A3A3F', duration: 2}, '<0%');
      tlAll.to(   analyzeHeaderSplit.chars, { autoAlpha: 0, filter: 'blur(10px)', duration: 1, stagger: {amount: 1, from: 'edges' } }, '<25%');
      // tlAll.from( analyzeBgCircle, { opacity: 0, duration: .1 }, '<0');
      tlAll.to(   analyzeBgCircleFill, { translateY: 0, duration: 3 }, '<75%');
      tlAll.to(   analyzeBgCircle, { rotation: '300deg', duration: 40 }, '<25%');
      tlAll.from( analyzeRightSplit.words, { autoAlpha: 0, y:'-=50', filter: 'blur(10px)', duration: 1, stagger: {amount: 1, from: 'end' }}, '<0%');
      tlAll.from( analyzeLeftSplit.words, { autoAlpha: 0, y:'+=50', filter: 'blur(10px)', duration: 1, stagger: {amount: 1 }}, '<0%');
      tlAll.fromTo( analyzeBtn, { autoAlpha: 0, filter: 'blur(10px)'}, { autoAlpha: 1, filter: 'blur(0px)', duration: 1}, '<100%');
      tlAll.to(   analyzeBtn, { autoAlpha: 0, filter: 'blur(10px)', duration: 1.6}, '<3');
      tlAll.to(   analyzeRightSplit.words, { autoAlpha: 0, filter: 'blur(10px)', duration: 2, stagger: {amount: 0}}, '<0%');
      tlAll.to(   analyzeLeftSplit.words, { autoAlpha: 0, filter: 'blur(10px)', duration: 2, stagger: {amount: 0 }}, '<0%');
      tlAll.to(   analyzeBgImage, { autoAlpha: 0, duration: 2},'<0.5');
      tlAll.to(   analyzeBgCircle, { opacity: 0, scale: 1.1, duration: 10 }, '<0');
      tlAll.to(   analyzeBgCircleFill, { backgroundColor: '#844A7A', duration: 3 }, '<1');
      tlAll.to(   analyzeMoons[0], { autoAlpha: 0, filter: 'blur(20px)', duration: 3 }, '<1');
      tlAll.to(   analyzeMoons[1], { autoAlpha: 0, filter: 'blur(20px)', duration: 3 }, '<0');
      tlAll.from( analyzeMoons[2], {autoAlpha: 0, filter: 'blur(20px)', duration: 3 }, '<0');

      // concept -------------------------
      tlAll.set(  analyze, { zIndex: 1}, '<0');
      tlAll.set(  concept, { zIndex: 2}, '<0');
      tlAll.from( conceptHeaderSplit.chars, { autoAlpha: 0, filter: 'blur(10px)', duration: 1, stagger: {amount: 1, from: 'edges' }}, '<1');
        tlAll.to( products, { backgroundColor: '#844A7A', duration: 10 }, '<1');
      tlAll.from( conceptBgImage, { autoAlpha: 0, filter: 'blur(10px)', duration: 2 }, '<4');
      tlAll.to(   conceptHeaderSplit.chars, { autoAlpha: 0, filter: 'blur(10px)', duration: 1, stagger: {amount: 1, from: 'edges' } }, '<0');
      tlAll.to(   analyzeMoons[2], {autoAlpha: 0, duration: 3 }, '<0');
      tlAll.from( conceptBgGraph, { autoAlpha: 0 }, '<50%');
      tlAll.from( conceptRightSplit.words, { autoAlpha: 0, y:'-=50', filter: 'blur(10px)', duration: 1, stagger: {amount: 1, from: 'end' }}, '<0%');
      tlAll.from( conceptLeftSplit.words, { autoAlpha: 0, y:'+=50', filter: 'blur(10px)', duration: 1, stagger: {amount: 1 }}, '<0%');
      tlAll.fromTo( conceptBtn, { autoAlpha: 0, filter: 'blur(10px)'}, { autoAlpha: 1, filter: 'blur(0px)', duration: 1}, '<100%');
        tlAll.to( analyzeBgCircleFill, { backgroundColor: '#C24967', duration: 3 }, '<4');
        tlAll.to( analyzeBgCircle, { opacity: 0.7, duration: 5 }, '<0.5');
      tlAll.to(   conceptBgImage, { autoAlpha: 0, filter: 'blur(10px)', duration: 2 }, '<0');
      tlAll.to(   conceptBtn, { autoAlpha: 0, filter: 'blur(10px)', duration: 1.6}, '<0');
      tlAll.to(   conceptRightSplit.words, { autoAlpha: 0, filter: 'blur(10px)', duration: 2, stagger: {amount: 0}}, '<0');
      tlAll.to(   conceptLeftSplit.words, { autoAlpha: 0, filter: 'blur(10px)', duration: 2, stagger: {amount: 0 }}, '<0');
      tlAll.to(   conceptBgGraph, { autoAlpha: 0 }, '<0');
      tlAll.set(  concept, { zIndex: 1}, '<0');
      tlAll.set(  design, { zIndex: 2}, '<0');
      tlAll.to(   analyzeMoons[2], {autoAlpha: 1, duration: 3 }, '<0');
      
      // design -------------------------------
      tlAll.set(  concept, { zIndex: 1}, '<0');
      tlAll.set(  design, { zIndex: 2}, '<0');
      tlAll.from( designHeaderSplit.chars, { autoAlpha: 0, filter: 'blur(10px)', duration: 1, stagger: {amount: 1, from: 'edges' }}, '<1');
      tlAll.to(   designHeaderSplit.chars, { autoAlpha: 0, filter: 'blur(10px)', duration: 1, stagger: {amount: 1, from: 'edges' }}, '<3');
        tlAll.to( products, { backgroundColor: '#D1788E', duration: 2 }, '<50%');
        tlAll.to( analyzeMoons[2], {autoAlpha: 0, duration: 3 }, '<0');
      tlAll.from( designBgImage, { autoAlpha: 0, rotation: '-=90deg', filter: 'blur(10px)', duration: 3}, '<0');
      tlAll.from( designBgGraph, { autoAlpha: 0, rotation: '-=90deg', filter: 'blur(10px)', duration: 3}, '<25%');
      tlAll.from( designRightSplit.words, { autoAlpha: 0, y:'-=50', filter: 'blur(10px)', duration: 1, stagger: {amount: 1, from: 'end' }}, '<0%');
      tlAll.from( designLeftSplit.words, { autoAlpha: 0, y:'+=50', filter: 'blur(10px)', duration: 1, stagger: {amount: 1 }}, '<0%');
      tlAll.fromTo( designBtn, { autoAlpha: 0, filter: 'blur(10px)'}, { autoAlpha: 1, filter: 'blur(0px)', duration: 1}, '<100%');
      tlAll.to(   designBgGraph, { autoAlpha: 0, rotation: '+=90deg', filter: 'blur(10px)', duration: 3}, '<3');
      tlAll.to(   designBgImage, { autoAlpha: 0, rotation: '+=90deg', filter: 'blur(10px)', duration: 3}, '<25%');
      tlAll.to(   designBtn, { autoAlpha: 0, filter: 'blur(10px)', duration: 1.6}, '<0');
      tlAll.to(   designRightSplit.words, { autoAlpha: 0, filter: 'blur(10px)', duration: 2, stagger: {amount: 0}}, '<0');
      tlAll.to(   designLeftSplit.words, { autoAlpha: 0, filter: 'blur(10px)', duration: 2, stagger: {amount: 0 }}, '<0');
      
      // validate -----------------------------
      tlAll.set(  design, { zIndex: 1}, '<0');
      tlAll.set(  validate, { zIndex: 2}, '<0');
        tlAll.to( analyzeMoons[2], {autoAlpha: 1, duration: 3 }, '<0');
        tlAll.to( analyzeBgCircleFill, { backgroundColor: '#768C94', duration: 3 }, '<0');
        tlAll.to( products, { backgroundColor: '#C5D9DF', duration: 2 }, '<25%');
      tlAll.from( validateHeaderSplit.chars, { autoAlpha: 0, filter: 'blur(10px)', duration: 1, stagger: {amount: 1, from: 'edges' }}, '<50%');
      tlAll.to(   validateHeaderSplit.chars, { autoAlpha: 0, filter: 'blur(10px)', duration: 1, stagger: {amount: 1, from: 'edges' }}, '<3');
        // tlAll.to( analyzeMoons[2], {autoAlpha: 0, duration: 3 }, '<0');
        tlAll.to( products, { backgroundColor: '#768C94', duration: 2 }, '<0');
      tlAll.from( validateBgImage, { autoAlpha: 0, duration: 2 }, '<1');
      tlAll.from( validateHand, { filter: 'blur(10px)', duration: 1 }, '<0');
      tlAll.from( validateRightSplit.words, { autoAlpha: 0, y:'-=50', filter: 'blur(10px)', duration: 1, stagger: {amount: 1, from: 'end' }}, '<0%');
      tlAll.from( validateLeftSplit.words, { autoAlpha: 0, y:'+=50', filter: 'blur(10px)', duration: 1, stagger: {amount: 1 }}, '<0%');
      tlAll.fromTo( validateBtn, { autoAlpha: 0, filter: 'blur(10px)'}, { autoAlpha: 1, filter: 'blur(0px)', duration: 1}, '<100%');
      tlAll.to( validateBtn, { autoAlpha: 1, duration: 2}, '<100%');
    },

    // all
    all: function () {
      // ScrollTriggers created here aren't associated with a particular media query,
      // so they persist.
    },
  });

  // ------------------------
  // bike animation

  let tlBike = gsap.timeline({
    repeat: -1,
    defaults: {
      duration: 1.75
    }
  });

  MorphSVGPlugin.convertToPath('#concept-dotBike, #concept-dotCircle');

  tlBike.set("#concept-tearS", {opacity: 0});
  tlBike.set("#concept-tearL", {opacity: 0});

  tlBike.set("#concept-dotBike", {opacity: 0});
  tlBike.set("#concept-dotCircle", {opacity: 0});

  tlBike.fromTo("#concept-moonL", {drawSVG: "100% 100%"}, {drawSVG: "0 100%"}, '0');
  tlBike.fromTo("#concept-moonS", {drawSVG: "0 0"}, {drawSVG: "100% 0", duration: 1.5}, '0');
  tlBike.fromTo("#concept-dotS", {scale: 0, transformOrigin: "50% 50%"},
  {
    scale: 1,
    duration: 1.5,
    motionPath:{
      path: "#concept-moonS",
      align: "#concept-moonS",
      alignOrigin: [0.5, 0.5]
    }
  }, '0');
  tlBike.fromTo("#concept-dotL", {scale: 0},
  {
    scale: 1,
    motionPath:{
      path: "#concept-moonL",
      align: "#concept-moonL",
      alignOrigin: [0.5, 0.5],
      start: 1,
      end: 0
    }
  }, '0');
  tlBike.from("#concept-lineL line", {duration: .15, drawSVG: 0, stagger: .1}, '0');
  tlBike.from("#concept-lineS line", {duration: .08, drawSVG: 0, stagger: .18}, '0');
  tlBike.to("#concept-dotS", {
    y: "-=50px",
    fill: '#654164',
    opacity: 0,
    duration: .45
  }, '1.26');

  tlBike.set("#concept-dotL", {opacity: 0}, '1.5');
  tlBike.set("#concept-dotBike", {opacity: 1}, '1.5');
  tlBike.set("#concept-dotCircle", {opacity: 1}, '1.5');
  
  tlBike.to("#concept-dotBike",{duration: .75, morphSVG:"#concept-tearL"}, '1.48');
  tlBike.to("#concept-dotCircle",{duration: .75, morphSVG:"#concept-tearS"}, '1.5');

  tlBike.fromTo("#concept-bike", {opacity: 0, scale: 0, yPercent:100, transformOrigin: "bottom center"}, {opacity: 1, scale: 1, yPercent:0, duration: .75}, '1.48');
  tlBike.fromTo("#concept-circle", {opacity: 0, scale: 0, yPercent:100, transformOrigin: "bottom center"}, {opacity: 1, scale: 1, yPercent:0, duration: .75}, '1.5');

  // hiding
  tlBike.to("#concept-lineL line", {duration: .15, drawSVG: 0, stagger: .1}, '1.5');
  tlBike.to("#concept-lineS line", {duration: .08, drawSVG: 0, stagger: .18}, '1.5');
  tlBike.fromTo("#concept-moonL", {drawSVG: "0 100%"}, {drawSVG: "0 0"}, '1.5');
  tlBike.fromTo("#concept-moonS", {drawSVG: "0 100%"}, {drawSVG: "100% 100%"}, '1.5');

  tlBike.to("#concept-dotBike",{duration: .5, opacity: 0}, '3');
  tlBike.to("#concept-dotCircle",{duration: .5, opacity: 0}, '3');

  tlBike.to("#concept-bike", {opacity: 0, duration: .5}, '3');
  tlBike.to("#concept-circle", {opacity: 0, duration: .5}, '3');


  // ----------------------------
  //dots astronaut animation

  gsap.set("#design-dotS", {
    fill: "#768C94"
  });
  gsap.set("#design-dotM", {
    fill: "#4A3A3F"
  });
  gsap.set("#design-dotL", {
    fill: "#D9F0F6"
  });

  gsap.to(["#design-dotS"], {
    duration: 7, 
    repeat: -1,
    ease: "none",
    motionPath:{
      path: "#design-pathIn",
      align: "#design-pathIn",
      alignOrigin: [0.5, 0.5]
    }
  });
  gsap.to(["#design-dotM"], {
    duration: 10, 
    repeat: -1,
    ease: "none",
    motionPath:{
      path: "#design-pathIn",
      align: "#design-pathIn",
      alignOrigin: [0.5, 0.5]
    }
  });
  gsap.from(["#design-dotL"], {
    duration: 14, 
    repeat: -1,
    ease: "none",
    motionPath:{
      path: "#design-pathOut",
      align: "#design-pathOut",
      alignOrigin: [0.5, 0.5]
    }
  });


  const bubbles = products.querySelectorAll('[data-anim-validate-clouds] img')
  let tlBubbles = [];

  bubbles.forEach((element, index) => {
    tlBubbles[index] = gsap.timeline({repeat: -1, delay: gsap.utils.random(1, 5)});
    tlBubbles[index].fromTo(element, {
      x: "30%"
    },{
      duration: gsap.utils.random(8, 12), 
      x: "-130%",
      ease: 'none'
    });
    tlBubbles[index].fromTo(element, {
      scale: 1,
      opacity: 1,
    },{
      scale: 1.5,
      opacity: 0,
      duration: .025,
      ease: 'Circ.easeIn'
    }, '<95%');
  });
  




}
