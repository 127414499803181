import { gsap } from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { is_mobile } from '../../_utilities/navigator';

gsap.registerPlugin(ScrollToPlugin);

export function Cta() {
  const cta = document.querySelectorAll('[data-cta]');

  console.log(is_mobile())
  if (!is_mobile()) {
    cta.forEach((btn) => {
      const dot = btn.querySelector('[data-dot]');
      gsap.set(dot, { scale: 0, autoAlpha: 1 });

      btn.addEventListener('mouseenter', (e) => {
        document.body.classList.add('is--btn');
        const xMouse = e.offsetX;
        const yMouse = e.offsetY;

        gsap.set(dot, { x: xMouse, y: yMouse });
        gsap.to(dot, { scale: 1, duration: 0.5 });
      });

      btn.addEventListener('mouseleave', (e) => {
        document.body.classList.remove('is--btn');

        const xMouse = e.offsetX;
        const yMouse = e.offsetY;

        gsap.set(dot, { x: xMouse, y: yMouse });
        gsap.to(dot, { scale: 0, duration: 0.5 });
      });
    });
  }
}
