import { gsap } from 'gsap';

export function ScrollIcon() {

  const scroll = document.querySelector('[data-scroll]');
  const arrow = scroll.querySelector('[data-arrow]');

  let arrowTl = gsap.timeline({ repeat: -1 });

  arrowTl.fromTo(
    arrow,
    { opacity: '0' },
    {
      opacity: '1',
      duration: 0.3,
    },
    '0'
  );
  arrowTl.fromTo(
    arrow,
    { y: '00%' },
    {
      y: '80%',
      duration: 1.4,
    },
    '0'
  );
  arrowTl.fromTo(
    arrow,
    { opacity: '1' },
    {
      opacity: '0',
      duration: 0.4,
    },
    '-=.4'
  );

  window.addEventListener(
    'scroll:smart',
    function () {
      if ( window.scrollY > (document.body.offsetHeight - window.innerHeight - 100) && !scroll.classList.contains('is--hidden') ){
        scroll.classList.add('is--hidden');
      }
      if ( window.scrollY < (document.body.offsetHeight - window.innerHeight - 100) && scroll.classList.contains('is--hidden') ){
        scroll.classList.remove('is--hidden');
      }
    }
  );

}
