import { gsap } from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';

gsap.registerPlugin(ScrollToPlugin);

export function Pagination() {
  const pagination = document.querySelector('[data-pagination]');
  const paginationEl = pagination.querySelectorAll('[data-goto]');
  let modulesOffset = [0];

  setTimeout(() => {
    getPositions();

    paginationEl.forEach((el, index) => {
      el.addEventListener('click', () => {
        window.scroll(0, modulesOffset[index]);
      });
    });
  }, 1000);

  window.addEventListener('scroll:smart', function () {
    for (let i = 0; i < modulesOffset.length; i++) {
      if (
        (i < modulesOffset.length - 1 &&
          window.scrollY < modulesOffset[i + 1] &&
          window.scrollY >= modulesOffset[i]) ||
        (i == modulesOffset.length - 1 && window.scrollY >= modulesOffset[i])
      ) {
        pagination.querySelector('.is--active').classList.remove('is--active');
        paginationEl[i].classList.add('is--active');
      }
    }
    // document.querySelector('[data-position]').innerHTML = window.scrollY;
  });

  window.addEventListener('resize:smart', function () {
    getPositions();
  });

  const getPositions = () => {
    const isDesktop = window.innerWidth >= 1024 ? true : false;
    paginationEl.forEach((el, index) => {
      
      if (index > 0) {
        const elId = el.getAttribute('data-goto');
        const prevElId = paginationEl[index-1].getAttribute('data-goto');
        if (
          (index == 1 || index == 2 || index == 3 || index == 4) &&
          isDesktop
        ) {
          modulesOffset[index] = 1500 + 6000 * (index - 1);
          // 1500 / 7400 / 13000 / 18000
          // switch (index) {
          //   case 1:
          //     modulesOffset[index] = 1500;
          //     break;
          //   case 2:
          //     modulesOffset[index] = 7400;
          //     break;
          //   case 3:
          //     modulesOffset[index] = 13000;
          //     break;
          //   case 4:
          //     modulesOffset[index] = 18000;
          //     break;
          // }
          if (index == 4) modulesOffset[index] = 18000;
        } else {
          if (
            !isDesktop
          ) {
            modulesOffset[index] = modulesOffset[index-1] + document.getElementById(prevElId).offsetHeight;
          } else {
            modulesOffset[index] = document.getElementById(elId).offsetTop;
          }
        }
        
      }

    });
    console.log(modulesOffset);
  };
}
