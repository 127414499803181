import { debounce } from './_utilities/debounce.js';
import { throttle } from './_utilities/throttle.js';
import { triggerEvent } from './_utilities/trigger-event.js';
import { is_mobile } from './_utilities/navigator.js';

import { Cursor } from './components/cursor/index';
import { Cta } from './components/cta/index';
import { ScrollIcon } from './components/scrollIcon/index';
import { Pagination } from './components/pagination/index';

import { Hero } from './modules/hero/index';
import { Products } from './modules/products/index';
import { Carousel } from './modules/carousel/index';
import { Form } from './modules/form/index';
import { Header } from './modules/header/index';
import { Team } from './modules/team/index';

/**
 * Binds all events to DOM objects.
 * @function _bindEvents
 */
function _bindEvents() {
  window.addEventListener(
    'resize',
    debounce(() => triggerEvent(window, 'resize:smart'), 250)
  );
  window.addEventListener(
    'scroll',
    throttle(() => triggerEvent(window, 'scroll:smart'), 250),
    { passive: true }
  );
}

_bindEvents();

Cursor();
Cta();
ScrollIcon();
Pagination();

Hero();
Products();
Carousel();
Form();
Header();
Team();
