// core version + navigation, pagination modules:
import Swiper, { Navigation, Pagination } from 'swiper';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

export function Carousel() {
  const carouselHead = document.querySelector('[data-anim-carousel-head]');
  const carouselBody = document.querySelector('[data-anim-carousel]');

  // moons animation
  const carouselMoons = carouselHead.querySelectorAll('[data-moon]');

  gsap.to(carouselMoons[0], {
    rotation: '-=360',
    duration: 60,
    repeat: -1,
    ease: 'none',
  });
  gsap.to(carouselMoons[1], {
    rotation: '+=360',
    duration: 80,
    repeat: -1,
    ease: 'none',
  });

  // -------------------------------------------------------------
  // carousel
  const carousel = carouselBody.querySelector('[data-swiper]');
  const outside = carouselBody.querySelector('[data-outside]');

  const btnNext = carouselBody.querySelector('[data-carousel-button-next]');
  const btnPrev = carouselBody.querySelector('[data-carousel-button-prev]');

  // init Swiper:
  const swiper = new Swiper(carousel, {
    // configure Swiper to use modules
    speed: 1000,
    modules: [Navigation, Pagination],
    navigation: {
      nextEl: btnNext,
      prevEl: btnPrev,
    },
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true,
    },
    on: {
      slideNextTransitionStart: function (swiper) {
        const prevSlide = outside.querySelector(
          '[data-outside-el="' + swiper.previousIndex + '"]'
        );
        const prevSlideBg = prevSlide.querySelector('[data-outside-bg]');
        const prevSlideElements = prevSlide.querySelectorAll(
          '[data-outside-content] > *'
        );
        const nextSlide = outside.querySelector(
          '[data-outside-el="' + swiper.activeIndex + '"]'
        );
        const nextSlideBg = nextSlide.querySelector('[data-outside-bg]');
        const nextSlideElements = nextSlide.querySelectorAll(
          '[data-outside-content] > *'
        );

        gsap.fromTo(prevSlideBg, { opacity: 1 }, { opacity: 0, duration: 1 });
        gsap.fromTo(
          prevSlideElements,
          { opacity: 1, x: 0 },
          {
            opacity: 0,
            x: '-50%',
            duration: 0.3,
            delay: 0.05,
            stagger: {
              each: 0.1,
              from: 'start',
            },
          }
        );

        gsap.fromTo(nextSlideBg, { opacity: 0 }, { opacity: 1, duration: 1 });
        gsap.fromTo(
          nextSlideElements,
          { opacity: 0, x: '50%' },
          {
            opacity: 1,
            x: '0',
            duration: 0.3,
            delay: 0.3,
            stagger: {
              each: 0.1,
              from: 'start',
            },
          }
        );
      },
      slidePrevTransitionStart: function (swiper) {
        const prevSlide = outside.querySelector(
          '[data-outside-el="' + swiper.previousIndex + '"]'
        );
        const prevSlideBg = prevSlide.querySelector('[data-outside-bg]');
        const prevSlideElements = prevSlide.querySelectorAll(
          '[data-outside-content] > *'
        );
        const nextSlide = outside.querySelector(
          '[data-outside-el="' + swiper.activeIndex + '"]'
        );
        const nextSlideBg = nextSlide.querySelector('[data-outside-bg]');
        const nextSlideElements = nextSlide.querySelectorAll(
          '[data-outside-content] > *'
        );

        gsap.fromTo(prevSlideBg, { opacity: 1 }, { opacity: 0, duration: 1 });
        gsap.fromTo(
          prevSlideElements,
          { opacity: 1, x: 0 },
          {
            opacity: 0,
            x: '50%',
            duration: 0.3,
            delay: 0.05,
            stagger: {
              each: 0.1,
              from: 'end',
            },
          }
        );

        gsap.fromTo(nextSlideBg, { opacity: 0 }, { opacity: 1, duration: 1 });
        gsap.fromTo(
          nextSlideElements,
          { opacity: 0, x: '-50%' },
          {
            opacity: 1,
            x: '0',
            duration: 0.3,
            delay: 0.3,
            stagger: {
              each: 0.1,
              from: 'end',
            },
          }
        );
      },
    },
  });

  const main = document.querySelector('[data-anim-carousel]');
  const mask = main.querySelector('[data-anim-carousel-mask]');

  ScrollTrigger.matchMedia({
    // desktop
    '(min-width: 1024px)': function () {
      let tl = gsap.timeline({
        duration: 4,
        scrollTrigger: {
          trigger: main,
          // markers: true,
          start: 'top top',
          end: '+=1000',
          scrub: true,
          pin: true,
          pinSpacing: false,
        },
      });

      tl.to(
        mask,
        { width: '102%', maxWidth: '102%', top: 0, bottom: 0, duration: 1, ease: 'none' },
        '<0'
      );
      // tl.to(
      //   mask,
      //   { width: '100%', duration: 0.25 }
      // );
    },
  });


  const cursor = document.querySelector('[data-cursor]');

  carouselBody.addEventListener('mouseenter', (e) => {
    document.body.classList.add('is--carousel');
  });

  carouselBody.addEventListener('mouseleave', (e) => {
    document.body.classList.remove('is--carousel');
  });

  checkSwiperPosition();
  carouselBody.addEventListener('click', (e) => {
    if (!swiper.animating){
      if (cursor.classList.contains('is--left')) {
        swiper.slidePrev();
      } else {
        swiper.slideNext();
      }
    }
    checkSwiperPosition();
  });

  function checkSwiperPosition(){
    if (swiper.isEnd) {
      cursor.classList.add('is--end');
    }else{
      cursor.classList.remove('is--end');
    }
    if (swiper.isBeginning) {
      cursor.classList.add('is--begin');
    }else{
      cursor.classList.remove('is--begin');
    }
  }
}
