import { gsap } from 'gsap';

export function Team() {
  const team = document.querySelector('[data-anim-team]');
  const people = team.querySelectorAll('[data-anim-people] picture');
    let peopleInd = 0;

  setInterval(() => {
    people[peopleInd].classList.remove('is--visible');
    peopleInd++;
    if (peopleInd >= people.length) peopleInd = 0;
    people[peopleInd].classList.add('is--visible');
  }, 700)

}
